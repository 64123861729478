import React, { Suspense } from "react";

import PageErrorBoundary from "./components/page-error-boundary.js";
import OrganizationRoutes from "./components/organization-routes.js";
import renderApplication, { GlobalLinks } from "./render-application";
import useLanguageChangeNotification from "./hooks/use-language-change-notification.js";
import { Scalars } from "./graphql-types";

type ID = Scalars["ID"];

const OrgCustomDomainRoutes = ({
  links: externalLinks,
}: {
  links: GlobalLinks;
}) => {
  useLanguageChangeNotification();

  const stellarHost = window.ENV.STELLAR_HOST;
  const links = {
    ...externalLinks,
    eventsIndex: `${stellarHost}/events`,
    wallet: `${stellarHost}/wallet`,
    walletItem: ({ occurrenceId }: { occurrenceId: ID }) =>
      `${stellarHost}/wallet/${occurrenceId}`,
    orgWalletItem: ({
      orgSlug,
      occurrenceId,
    }: {
      orgSlug: string;
      occurrenceId: ID;
    }) => `${stellarHost}/o/${orgSlug}/wallet/${occurrenceId}`,
    settings: `${stellarHost}/settings`,
    purchases: `${stellarHost}/settings/purchases`,
    language: `${stellarHost}/settings/language`,
    subscriptions: `${stellarHost}/settings/subscriptions`,
    dashboard: `${stellarHost}/dashboard`,
    help: `${stellarHost}/pages/help`,
  };
  return (
    <Suspense fallback={null}>
      <PageErrorBoundary key="org-home">
        <OrganizationRoutes links={links} />
      </PageErrorBoundary>
    </Suspense>
  );
};

renderApplication({
  RoutesComponent: OrgCustomDomainRoutes,
});
